import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AxiosResponse } from "axios";
import indiaflag from "../../assets/images/india-flag.png";
import japanflag from "../../assets/images/japan-flag.png";
import contact from "../../assets/images/contact.png";
import { useForm } from "react-hook-form";
import { postContactDetails } from "../../utils/api";
import { axiosPost } from "../../utils/helper";
import { toast, ToastContainer } from "react-toastify";
import contactusbanner from "../../assets/images/banner/contact-banner.jpg";
import "./contactus.scss";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

function Contactus() {
  let response: AxiosResponse;
  const [isVerified, setIsVerified] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleVerify = (response: string | null) => {
    // 'response' will be null if the user cancels the reCAPTCHA
    if (response) {
      setIsVerified(true);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      await postContactData(postContactDetails, data);
      reset();
    } catch (error) {
      console.error("Error posting");
    }
  };

  async function postContactData(reqUrl: string, reqBody: string) {
    try {
      const response = (await axiosPost(reqUrl, reqBody)) as AxiosResponse;
      if (response.status === 200) {
        toast.success("Successful submit", {});
      }
      return response;
    } catch (error: any) {
      throw error;
    }
  }
  const breadcrumbItems = [
    { label: 'Home', link: '/' },
    { label: 'About Synoverge',link:'/our-story' },
    { label: 'Contact Us' },
  ];

  return (
    <Container fluid className="px-0">
       <Helmet>
        <title>
        Contact Synoverge | Get in Touch Now  | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="
          We are here to support the success of your company. Reach out to Synoverge. Get in touch for professional IT assistance, questions, and partnerships."
        />
        <link rel="canonical"href="https://www.synoverge.com/contact-us" />
      </Helmet>
      <div className="contact-us">
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Contact Us</h1>
                  {/* <h1>Transforming Data into Intelligence</h1> */}
                  <p>Improve decision making through business insights with our analytics roadmap</p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={contactusbanner} alt="Contactus" title="Contactus" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
            <CustomBreadcrumb items={breadcrumbItems} />
            <Row>
            <h3>Get In Touch</h3>
              <Col xs={12} md={4}>
                <div className="contact-footer">
                  <div>
                    <img src={indiaflag} alt="india" title="india" className="flagimg" />
                  </div>
                  <h4>Synoverge India</h4>
                  <p>
                    The Summit, 3 Sunrise Park,
                    <br />
                    Sunrise Park Road, Bodakdev,
                    <br />
                    Ahmedabad 380054, Gujarat - India.
                  </p>
                  <p>
                    <strong>Tel.:</strong>{" "}
                    <Link to="tel:+917949062116">+91 79 49062116</Link>/
                    <Link to="tel:+917949062117">7</Link>
                  </p>
                  <p>
                    <strong>US Number:</strong> 201-565-9282
                  </p>
                  <p>
                    <strong>Skype:</strong> <Link to="skype:synoverge">synoverge</Link>
                  </p>
                  <p>
                    <strong>Email: </strong>
                    <Link to="mailto:info@synoverge.com">info@synoverge.com</Link>
                  </p>
                  <div className="mt-40">
                    <iframe
                      title="Map"
                      src={
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.41632148027!2d72.5275071153543!3d23.04519372126436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84b05399196f%3A0xee01b0990d6ca12c!2sSynoverge%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1583398895233!5m2!1sen!2sin"
                      }
                      height={350}
                      width={"100%"}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="contact-footer">
                  <div>
                    <img src={japanflag} alt="japan" title="japan" className="flagimg" />
                  </div>
                  <h4>Synoverge Japan Co., Ltd.</h4>
                  <p>
                    7-5-14, Funabori, Edogawa-Ku,
                    <br />
                    Tokyo, Japan 〒134-0091
                  </p>
                  <p>
                    <strong>Tel.:</strong>{" "}
                    <Link to="tel:+81(3) 68770905">+81 (3) 6877-0905</Link>
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    <Link to="mailto:japan@synoverge.com">japan@synoverge.com</Link>
                  </p>
                  <div className="mt-132">
                    <iframe
                      title="Map"
                      src={
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.9281450695153!2d139.86830751510573!3d35.678771237714564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601887c69ba2b2f5%3A0x5b32029eb22a04b4!2s7-ch%C5%8Dme-5-14%20Funabori%2C%20Edogawa%20City%2C%20Tokyo%20134-0091%2C%20Japan!5e0!3m2!1sen!2sin!4v1650538739264!5m2!1sen!2sin"
                      }
                      height={350}
                      width={"100%"}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="contact-footer">
                  <div>
                    <img src={contact} alt="contact" title="contact" className="flagimg" />
                  </div>
                  <h4>Feel Free To Write To Us</h4>
                  <Form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Your Name*"
                            {...register("Name", { required: "Name is required" })}
                          />
                          {errors.Name && (
                            <p style={{ color: "red" }}>
                              {errors.Name.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Email ID*"
                            {...register("Email", {
                              required: "Email is required",
                              pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Invalid email format",
                              },
                            })}
                          />
                          {errors.Email && (
                            <p style={{ color: "red" }}>
                              {errors.Email.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Company*"
                            {...register("Company", {
                              required: "Company is required",
                            })}
                          />
                          {errors.Company && (
                            <p style={{ color: "red" }}>
                              {errors.Company.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder=" Title*"
                            {...register("Title", { required: "Title is required" })}
                          />
                          {errors.Title && (
                            <p style={{ color: "red" }}>
                              {errors.Title.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="contact-comments">
                          <Form.Control
                            as="textarea"
                            placeholder="Comments*"
                            rows={5}
                            {...register("Comments", {
                              required: "Comments is required",
                            })}
                          />
                          {errors.Comments && (
                            <p style={{ color: "red" }}>
                              {errors.Comments.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                      <Form.Group className="contact-captcha">
                      <ReCAPTCHA
                        sitekey="6LetrCYpAAAAAPk24gcHCpl16hAYzC8_87MARhWv"
                        onChange={handleVerify}
                      />
                      </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mt-3">
                          <Button
                            type="submit"
                            value="Submit"
                            className="submit-btn submit"
                            disabled={!isVerified}
                          >Submit</Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  );
}

export default Contactus;
