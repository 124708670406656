import React, { Fragment, Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Carousel, Form, Button } from "react-bootstrap";
import enterpriseapp from "../../assets/images/animated-icons/destination.gif";
import digitrans from "../../assets/images/animated-icons/transform.gif";
import platformengg from "../../assets/images/animated-icons/indentation.gif";
import verifyvalidate from "../../assets/images/animated-icons/safe.gif";
import webicon from "../../assets/images/animated-icons/settings.gif";
import mobility from "../../assets/images/animated-icons/computer.gif";
import cloud from "../../assets/images/animated-icons/clouds.gif";
import code from "../../assets/images/animated-icons/coding.gif";
import search from "../../assets/images/animated-icons/search.gif";
import microsofticon from "../../assets/images/animated-icons/microsoft-logo.gif";
import journey from "../../assets/images/journey-so-far.gif";
import usericon1 from "../../assets/images/user.png";
import usericon2 from "../../assets/images/user.png";
import clutch from "../../assets/images/clutch.png";
import microsoft from "../../assets/images/microsoft-lg-1.png";
import nasscom from "../../assets/images/nasscom-lg-1.png";
import ISOlogo from "../../assets/images/ISO-27001-logo.png";
import ISOlogo1 from "../../assets/images/ISO-9001-logo.png";
import reading from "../../assets/images/animated-icons/fast-forward.gif";
import ReactPlayer from 'react-player/youtube'
import { Helmet } from 'react-helmet';
import "./home.scss";


import CountUp from 'react-countup';



const testimonial = [

  {

    content1:

      "Synoverge team has done an outstanding work and shown great willingness to adapt to our needs/requirements. I appreciate the team’s flexibility to meet our changing needs/goals. We are looking forward to continuing to do business together.",

    author1: "President & CEO",

    expertise1: "(a leading agriculture yield management platform in the US)",

   // userico1: { usericon1 },



    content2:

      "Using Synoverge has allowed us to deliver higher quality products and services in a timely fashion. Synoverge’s project management and team allocation approach has increased our project success rates and shortened our execution cycles.",

    author2: "CTO",

    expertise2: "(a niche ISV providing law &amp; enforcement solutions to state agencies in US)",

    //userico2: { usericon2 },



    content3:

      "It’s been a pleasure working with Synoverge team and Project Manager in particular…. amazing attitude and determination. Keep up the good work.",

    author3: "VP, Technology Services",

    expertise3: "(one of the global leaders providing professional services)",

    //userico3: { usericon1 },



    content4:

      "Synoverge team has done an outstanding work and shown great willingness to adapt to our needs/requirements. I appreciate the team’s flexibility to meet our changing needs/goals. We are looking forward to continuing to do business together.",

    author4: "President & CEO",

    expertise4: "(a leading agriculture yield management platform in the US)",

    //userico4: { usericon2 }





  }

];



function Home() {
  //  for scrolling postion then increase number
  const [isVisible, setIsVisible] = useState(false);
  const endCount = 14
  const endCount1 = 109
  const endCount2 = 250
  const endCount3 = 3
  const endCount4 = 35
  const endCount5 = 13

  const handleScroll = () => {
    const section = document.querySelector(".synoverge-glance");
    if (section) {
      const sectionTop = section.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (sectionTop < windowHeight) {
        setIsVisible(true);
      }
    }
  };

  //  scroll event listener when the component mounts

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //for seo
 const keywords ="software development company, software development services, software product development, software product development service, mobile app software development service, web software development services, mobile software development company, mobile app development services";

  return (
    <Fragment>
       <Helmet>
        <meta name="robots" content="index,follow"/>
        <title>Software Product Development | Mobile App Development</title>
        <meta name="description" content="Synoverge, an ISO-certified leader with over 14 years in software development, specializes in custom software and mobile app solutions." />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content="Software Product Development | Mobile App Development" />
        <meta property="og:description" content="Synoverge, an ISO-certified leader with over 14 years in software development, specializes in custom software and mobile app solutions." />
        <meta property="og:url" content="https://www.synoverge.com"/>
        <meta name="twitter:title" content="Software Product Development | Mobile App Development" />
        <meta name="twitter:description" content="Synoverge, an ISO-certified leader with over 14 years in software development, specializes in custom software and mobile app solutions." />
        </Helmet>
      <div className="landing-page">
        <Container>
          <section className="banner-sec">
            <Row>
              <Col lg={6}>
                <div className="banner-content">
                  <h1>Ideate. Energize. Evolve.</h1>
                  <h6>
                    Bringing ideas to fruition on time, within budget, to build
                    robust and scalable solutions.
                  </h6>
                  <button className="btn btn-knowmore none">Know More</button>
                </div>
              </Col>
              <Col lg={6}>
                <div className="banner-img">
                  <div className="bannerframe"></div>
                </div>
              </Col>
            </Row>
          </section>
        </Container>

        <section className="services-sec" >
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <h2 className="sectitle">Our <span>Services</span></h2>
              </Col>
              <Col lg={12} md={12}>
                <div className="right-sec">
                  <Row>
                    <Col lg={3} md={6}>
                      <div className="service-sec">
                        <div className="service-ico light-green">
                          <img width="80px" height="80px" src={digitrans} alt="" loading="lazy" />
                        </div>
                        <h4>Digital Transformation</h4>
                        <p>
                          Make your business ready for the unpredictable challenges of the digital era with our Digital Transformation Service. We deliver highly scalable and customizable solutions that can be tailored to meet the unique needs of your business.
                        </p>
                        <Link to="digital-transformation">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="service-sec">
                        <div className="service-ico light-magenta">
                          <img width="80px" height="80px" src={platformengg} alt="" loading="lazy" />
                        </div>
                        <h4>Platform <br></br> Engineering</h4>
                        <p>
                          Revolutionize your product delivery with our platform engineering service. With a decade of experience in platform engineering, we deliver innovative solutions to keep you ahead of the tech curve. Delight your customers and stay competitive.
                        </p>
                        <Link to="product-engineering-services">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="service-sec">
                        <div className="service-ico mild-blue">
                          <img width="80px" height="80px" src={enterpriseapp} alt="" loading="lazy" />
                        </div>
                        <h4>Enterprise Applications</h4>
                        <p>
                          Introduce a new paradigm of services in your organization with our extensive Enterprise Applications offering. Our deep expertise & experience in global Enterprise solutions make us a perfect partner to provide industry specific solutions.
                        </p>
                        <Link to="enterprise-applications">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="service-sec">
                        <div className="service-ico mild-purple">
                          <img width="80px" height="80px" src={verifyvalidate} alt="" loading="lazy" />
                        </div>
                        <h4>Verification Validation</h4>
                        <p>
                          Enhance your software with our Verification & Validation services. Streamline development, reduce costs, & increase product value. Our services can help you reduce the product ownership cost, time to market, product maintenance & upgradation cost.
                        </p>
                        <Link to="verification-and-validation">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Container>
          <section className="synoverge-glance">
            <h2 className="sectitle">
              Synoverge at a <span>Glance</span>
            </h2>
            <Row>
              <Col lg={2} md={4} sm={12}>
                <div className="yearsofexcellence">
                  <div className="yoex-value">
                    {isVisible && <><CountUp end={endCount} delay={0.25} /><span>+</span></>}
                  </div>
                  <p>Years of Excellence</p>
                </div>
              </Col>
              <Col lg={2} md={4} sm={12}>
                <div className="engagements">
                  <div className="yoex-value1">
                    {isVisible && <><CountUp end={endCount1} /><span>+</span></>}
                  </div>
                  <p>Engagements</p>
                </div>
              </Col>
              <Col lg={2} md={4} sm={12}>
                <div className="yearsofexcellence">
                  <div className="yoex-value">
                    {isVisible && <><CountUp end={endCount2} delay={0.25} /><span>+</span></>}
                  </div>
                  <p>Employees</p>
                </div>
              </Col>
              <Col lg={2} md={4} sm={12}>
                <div className="engagements">
                  <div className="yoex-value1">
                    {isVisible && <><CountUp end={endCount3} /><span>+</span></>}
                  </div>
                  <p>Locations</p>
                </div>
              </Col>
              <Col lg={2} md={4} sm={12}>
                <div className="yearsofexcellence">
                  <div className="yoex-value">
                    {isVisible && <><CountUp end={endCount4} delay={0.25} /><span>+</span></>}
                  </div>
                  <p>Products Designed</p>
                </div>
              </Col>
              <Col lg={2} md={4} sm={12}>
                <div className="engagements">
                  <div className="yoex-value1">
                    {isVisible && <><CountUp end={endCount5} /><span>+</span></>}
                  </div>
                  <p>Startup Enabled</p>
                </div>
              </Col>
            </Row>
          </section>
        </Container>

        <section className="techcoes-sec">
          <Container fluid="md">
            <h2 className="sectitle">
              Technology <span>CoEs</span>{" "}
            </h2>
            <Row>
              <Col lg={4} md={6}>
                <div className="tech-coes ">
                  <div className="tech-ico light-magenta">
                    <img width="80px" height="80px" src={webicon} alt="" loading="lazy" />
                  </div>
                  <h4>AI, BI & Data</h4>
                  <p>
                    We work with you in realizing the value of data at scale. With in-depth knowledge, a model optimized for speed and efficiency; we partner with you in navigating your analytics journey with ease.
                  </p>
                  <Link to="ai-bi-data">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="tech-coes ">
                  <div className="tech-ico light-magenta">
                    <img width="80px" height="80px" src={mobility} alt="" loading="lazy" />
                  </div>
                  <h4>Mobility & Devices</h4>
                  <p>
                    We understand the importance of keeping up with your changing business and requirements. That's why we offer comprehensive Mobility & IOT Solutions and Management Services that can help you stay ahead of your competition.
                  </p>
                  <Link to="iot-mobility-devices">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="tech-coes  ">
                  <div className="tech-ico mild-blue">
                    <img width="80px" height="80px" src={cloud} alt="" loading="lazy" />
                  </div>
                  <h4>Cloud</h4>
                  <p>
                    Tap into the power of Cloud Computing Services and give your business an enormous competitive advantage. Our Cloud Experts can help you work collaboratively on the go, and across Cloud platforms.
                  </p>
                  <Link to="cloud">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="tech-coes ">
                  <div className="tech-ico light-magenta">
                    <img width="80px" height="80px" src={code} alt="" loading="lazy" />
                  </div>
                  <h4>DevOps</h4>
                  <p>
                    Unlock your team's potential with our DevOps Services. Optimize your work environment, improve productivity, and streamline software development and delivery. Achieve more with the right DevOps tools and processes.
                  </p>
                  <Link to="devops">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="tech-coes ">
                  <div className="tech-ico light-green">
                    <img width="80px" height="80px" src={microsofticon} alt="" loading="lazy" />
                  </div>
                  <h4>Microsoft Technologies</h4>
                  <p>
                    As a Microsoft Gold Partner, we keep pace with the latest technologies and offerings. Our Microsoft-certified experts in Development Services build custom solutions to meet your business needs, with precision and efficiency.
                  </p>
                  <Link to="microsoft-technologies">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="tech-coes ">
                  <div className="tech-ico mild-purple">
                    <img width="80px" height="80px" src={search} alt="" loading="lazy" />
                  </div>
                  <h4>Open Source Technologies</h4>
                  <p>
                    Get the most out of open-source technologies with our development services. Leverage their flexibility, security, and quality to create custom solutions that meet your unique business needs. Trust our deep expertise in open-source development.
                  </p>
                  <Link to="open-source-technologies">Continue Reading <img width="16px" height="16px" src={reading} alt="banner" loading="lazy" className="animate-service-icon" /></Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="journey-so-far desktop-view">
          <Container>
            <h2 className="sectitle">
              Journey <span>so far</span>{" "}
            </h2>
            <div className="journey-img">
              <img width="1100px" height="263px" src={journey} alt="" loading="lazy" />
            </div>
          </Container>
        </section>
        <section className="client-say">
          <Container>
            <h2 className="sectitle">
              What our <span>Clients Say</span>
            </h2>
            <div>


              <Carousel interval={5000} className="carousel desktop-view">
                {testimonial.map((c, index) => {
                  const slides = [];
                  if (c.content1 && c.content2) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content1}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author1}</h5>
                                  <p className="expertise">{c.expertise1}</p>
                                </div>
                              </div>
                            </Col>
                            {/* Right Side Column */}
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content2}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author2}</h5>
                                  <p className="expertise">{c.expertise2}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  if (c.content3 && c.content4) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content3}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author3}</h5>
                                  <p className="expertise">{c.expertise3}</p>
                                </div>
                              </div>
                            </Col>
                            {/* Right Side Column */}
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                <div className="testimonial-content">
                                  <p>"{c.content4}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author2}</h5>
                                  <p className="expertise">{c.expertise4}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  if (c.content3 && c.content4) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content3}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author3}</h5>
                                  <p className="expertise">{c.expertise3}</p>
                                </div>
                              </div>
                            </Col>
                            {/* Right Side Column */}
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content4}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author2}</h5>
                                  <p className="expertise">{c.expertise4}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  if (c.content3 && c.content4) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content3}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author3}</h5>
                                  <p className="expertise">{c.expertise3}</p>
                                </div>
                              </div>
                            </Col>
                            {/* Right Side Column */}
                            <Col lg={6}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content4}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author2}</h5>
                                  <p className="expertise">{c.expertise4}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  return slides;
                })}
              </Carousel>

              <Carousel interval={5000} className="carousel mobile-view">
                {testimonial.map((c, index) => {
                  const slides = [];
                  if (c.content1 && c.content2) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={12}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content1}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author1}</h5>
                                  <p className="expertise">{c.expertise1}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  if (c.content3 && c.content4) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={12}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content2}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author2}</h5>
                                  <p className="expertise">{c.expertise2}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  if (c.content3 && c.content4) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={12}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content3}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author3}</h5>
                                  <p className="expertise">{c.expertise3}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  if (c.content3 && c.content4) {
                    slides.push(
                      <Carousel.Item key={index} interval={5000}>
                        <div className="testimonial">
                          <Row>
                            <Col lg={12}>
                              <div className="testimonial-bg">
                                
                                <div className="testimonial-content">
                                  <p>"{c.content4}"</p>
                                </div>
                                <div className="testimonial-author">
                                  <h5>{c.author4}</h5>
                                  <p className="expertise">{c.expertise4}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Carousel.Item>
                    );
                  }
                  return slides;
                })}
              </Carousel>
            </div>
          </Container>
        </section>

        <section>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <section className="workprocess">
                  <h2 className="title">Excellent <span> Work Process</span></h2>
                  <div className="position-relative video-text">
                    <ReactPlayer url='https://youtu.be/ZgdqYaSJIOs' className="video-youtube" />
                  </div>
                </section>
              </Col>

            </Row>
          </Container>
          </section>
          <section className="award-sec desktop-view" >
            <Container>
              <Col xs={12} md={12}>
                <section className="awards">
                  <Container>
                    <div>
                      <h2 className="title">Awards & <span>Accolades</span></h2>
                      <ul>
                        <li><Link to="https://clutch.co/profile/synoverge-technologies-private#highlights" target="_blank"><img width="115px" height="100%" src={clutch} alt="clutch" loading="lazy" /></Link></li>
                        <li><Link to="#" target="_blank"><img width="115px" height="100%" src={microsoft} alt="microsoft" loading="lazy" /></Link></li>
                        <li><Link to="#" target="_blank"><img width="115px" height="100%" src={nasscom} alt="nasscom" loading="lazy" /></Link></li>
                        <li><Link to="#" target="_blank"><img width="115px" height="100%" src={ISOlogo} alt="ISOlogo" loading="lazy" /></Link></li>
                        <li><Link to="#" target="_blank"><img width="115px" height="100%" src={ISOlogo1} alt="ISOlogo1" loading="lazy" /></Link></li>
                      </ul>
                    </div>

                  </Container>
                </section>
              </Col>
            </Container>
          </section>
        
      </div>
    </Fragment>
  );
}

export default Home;
